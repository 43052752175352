import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import PublishingServices from './pages/PublishingServices';
import MarketingServices from './pages/MarketingServices';
import About from './pages/About';
import Contact from './pages/Contact';
import AnnouncementBar from './components/AnnouncementBar';
import GoogleRatingWidget from './components/GoogleRatingWidget';
import './styles/App.css';

// Initialize GA4
ReactGA.initialize('G-TJ79QMWZDH');

// Create a ScrollToTop component that also tracks page views
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      ReactGA.send({ hitType: "pageview", page: pathname });
    } catch (error) {
      console.error('Error in ScrollToTop:', error);
    }
  }, [pathname]);

  return null;
}

// Add Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error caught:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h2>Something went wrong.</h2>
          <button onClick={() => window.location.reload()}>Refresh Page</button>
        </div>
      );
    }

    return this.props.children;
  }
}

function App() {
  useEffect(() => {
    // Add Fastbots script with error handling
    const script = document.createElement('script');
    script.src = 'https://app.fastbots.ai/embed.js';
    script.defer = true;
    script.setAttribute('data-bot-id', 'cm5wl1jmu05lrs5bwaas7g29t');
    
    // Add error handling for script loading
    script.onerror = (error) => {
      console.error('Error loading Fastbots script:', error);
    };
    
    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <ScrollToTop />
        <div className="app">
          <AnnouncementBar />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/publishing-services" element={<PublishingServices />} />
            <Route path="/marketing-services" element={<MarketingServices />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
          <GoogleRatingWidget />
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App; 