import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { trackButtonClick } from '../utils/analytics';
import '../styles/VideoHeader.css';

const VideoHeader = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleContactClick = () => {
    trackButtonClick('Contact Us - Video Header');
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      const progress = Math.min(scrollPosition / (viewportHeight * 0.4), 1);
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Load Vimeo Player API script
    const script = document.createElement('script');
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    script.onload = () => setIsVideoLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="video-header">
      {!isVideoLoaded && (
        <div className="video-placeholder">
          {/* Loading placeholder */}
        </div>
      )}
      <div className="vimeo-wrapper">
        <iframe 
          src="https://player.vimeo.com/video/1071934120?h=e185da2932&badge=0&autopause=0&player_id=0&app_id=58479&background=1&autoplay=1&loop=1&muted=1&quality=2160p"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center center',
            transform: 'scale(1.4)',
            transformOrigin: 'center'
          }}
          className={isVideoLoaded ? 'loaded' : ''}
          title="vidheader3"
        ></iframe>
      </div>
      <div className="video-overlay">
        <div className="header-content">
          <div className="trust-badges">
            <img 
              src="/Images/bbb-logo.jpg" 
              alt="BBB Accredited Business" 
              className="trust-badge"
              onError={(e) => {
                console.error('Failed to load BBB logo');
                e.target.style.display = 'none';
              }}
            />
            <img 
              src="/Images/ibpa-logo.png" 
              alt="Independent Book Publishers Association" 
              className="trust-badge"
              onError={(e) => {
                console.error('Failed to load IBPA logo');
                e.target.style.display = 'none';
              }}
            />
            <div className="trustpilot-container">
              <img 
                src="/Images/trustpilot-logo.png" 
                alt="Trustpilot" 
                className="trust-badge"
                onError={(e) => {
                  console.error('Failed to load Trustpilot logo');
                  e.target.style.display = 'none';
                }}
              />
              <div className="verified-company">
                <span className="check-icon">✓</span>
                <span>VERIFIED COMPANY</span>
              </div>
            </div>
          </div>
          <h1 className="brand-title">
            Executive Press
          </h1>
          <Link to="/contact" className="contact-button" onClick={handleContactClick}>
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VideoHeader; 