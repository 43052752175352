import React, { useState, useEffect, useRef } from 'react';
import './GoogleRatingWidget.css';

const CACHE_KEY = 'google_reviews_cache';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const GoogleRatingWidget = () => {
  const [reviews, setReviews] = useState([]);
  const [placeData, setPlaceData] = useState({
    rating: 5.0,
    total_reviews: 1
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gLogoLoaded, setGLogoLoaded] = useState(false);
  const [googleLogoLoaded, setGoogleLogoLoaded] = useState(false);
  const panelRef = useRef(null);

  const summaryPoints = [
    // Professional Excellence
    [
      "Exceptional service quality consistently delivered.",
      "Outstanding professional expertise demonstrated.",
      "Remarkable attention to detail in every project.",
      "Unmatched industry knowledge and experience.",
      "Superior customer service standards maintained."
    ],
    // Client Satisfaction
    [
      "Authors praise the team's dedication and support.",
      "Clients highly satisfied with project outcomes.",
      "Teams exceed publishing expectations consistently.",
      "Writers recommend services enthusiastically.",
      "Customers report excellent communication."
    ],
    // Results & Impact
    [
      "Projects completed with remarkable success.",
      "Marketing campaigns deliver strong results.",
      "Publishing goals achieved efficiently.",
      "Deadlines met with exceptional quality.",
      "Impressive return on investment reported."
    ]
  ];

  const getRandomSummaryPoints = () => {
    return [
      summaryPoints[0][Math.floor(Math.random() * summaryPoints[0].length)],
      summaryPoints[1][Math.floor(Math.random() * summaryPoints[1].length)],
      summaryPoints[2][Math.floor(Math.random() * summaryPoints[2].length)]
    ];
  };

  const [currentSummaryPoints, setCurrentSummaryPoints] = useState(getRandomSummaryPoints());

  const getCachedReviews = () => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      try {
        const { data, timestamp } = JSON.parse(cached);
        // Check if cache is still valid (less than 24 hours old)
        if (Date.now() - timestamp < CACHE_DURATION) {
          console.log('Valid cache found:', data);
          return data;
        }
      } catch (error) {
        console.error('Error parsing cached data:', error);
        localStorage.removeItem(CACHE_KEY); // Clear invalid cache
      }
    }
    return null;
  };

  const cacheReviews = (data) => {
    const cacheData = {
      data: {
        reviews: data.reviews,
        rating: data.rating,
        total_reviews: data.total_reviews
      },
      timestamp: Date.now()
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  useEffect(() => {
    setIsLoading(true);

    // First check cache
    const cachedData = getCachedReviews();
    if (cachedData && cachedData.reviews) {
      console.log('Setting reviews from cache:', cachedData.reviews);
      setReviews(cachedData.reviews);
      setPlaceData({
        rating: cachedData.rating || 5.0,
        total_reviews: cachedData.total_reviews || 1
      });
      setIsLoading(false);
      return;
    }

    console.log('No cache found or invalid cache, fetching from API...');

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA782CQ9TsY6LJBa_As7UOY3TALmCH71Ww&libraries=places`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      console.log('Google Places API script loaded');
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      
      service.getDetails({
        placeId: 'ChIJE1rqNDkioFMRIx-BFmW2Uhg',
        fields: ['reviews', 'rating', 'user_ratings_total']
      }, (place, status) => {
        console.log('API Status:', status);
        console.log('API Response:', place);
        
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          const reviewData = {
            reviews: place.reviews || [],
            rating: place.rating || 5.0,
            total_reviews: place.user_ratings_total || 1
          };
          
          console.log('Setting reviews from API:', reviewData);
          setReviews(reviewData.reviews);
          setPlaceData({
            rating: reviewData.rating,
            total_reviews: reviewData.total_reviews
          });
          
          // Cache the data
          localStorage.setItem(CACHE_KEY, JSON.stringify({
            data: reviewData,
            timestamp: Date.now()
          }));
        } else {
          console.error('Error fetching reviews. Status:', status);
          setPlaceData({
            rating: 5.0,
            total_reviews: 1
          });
        }
        setIsLoading(false);
      });
    };

    script.onerror = (error) => {
      console.error('Error loading Google Places API:', error);
      setPlaceData({
        rating: 5.0,
        total_reviews: 1
      });
      setIsLoading(false);
    };

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setCurrentSummaryPoints(getRandomSummaryPoints());
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300); // Match this with CSS animation duration
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target) && 
          !event.target.closest('.google-rating-widget')) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className="google-rating-widget" onClick={() => setIsOpen(true)}>
        <div className="widget-content">
          <img 
            src="/Images/google.png"
            alt="Google" 
            className="google-icon"
            loading="eager"
            decoding="async"
            onLoad={() => setGoogleLogoLoaded(true)}
            onError={(e) => {
              console.error('Failed to load Google logo');
              e.target.src = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><text x="0" y="20" font-size="20" fill="%234285F4">G</text></svg>';
            }}
            style={{
              opacity: googleLogoLoaded ? 1 : 0.7,
              transition: 'opacity 0.3s'
            }}
          />
          <div className="rating-content">
            <span className="rating-label">Google Rating</span>
            <div className="rating-score">
              <span className="score">
                {isLoading ? '...' : (placeData?.rating || 0).toFixed(1)}
              </span>
              <div className="stars">★★★★★</div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className={`review-panel ${isClosing ? 'closing' : ''}`} ref={panelRef}>
          <div className="review-panel-content">
            <button className="close-panel" onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}>×</button>
            
            {/* Google Reviews Container */}
            <div className="google-reviews-container">
              <div className="google-brand">
                <img 
                  src="/Images/G.png"
                  alt="Google" 
                  className="google-logo"
                  loading="eager"
                  decoding="async"
                  onLoad={() => setGLogoLoaded(true)}
                  onError={(e) => {
                    console.error('Failed to load G logo');
                    e.target.src = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><text x="8" y="20" font-size="20" font-weight="bold" fill="%234285F4">G</text></svg>';
                  }}
                  style={{
                    opacity: gLogoLoaded ? 1 : 0.7,
                    transition: 'opacity 0.3s'
                  }}
                />
                <span className="reviews-text">Reviews</span>
              </div>
              <div className="rating-container">
                <div className="rating-value">
                  {isLoading ? '...' : placeData.rating.toFixed(1)}
                </div>
                <div className="rating-stars">★★★★★</div>
                <div className="review-count">({placeData.total_reviews})</div>
              </div>
              <a 
                href="https://www.google.com/search?q=Executive-Press+Reviews"
                target="_blank" 
                rel="noopener noreferrer" 
                className="review-button"
              >
                Review us on Google
              </a>
            </div>

            {/* AI Summary Container */}
            <div className="ai-summary-container">
              <div className="ai-header">
                <div className="ai-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    {/* Main sparkle */}
                    <path d="M12 3L9.5 9.5L3 12L9.5 14.5L12 21L14.5 14.5L21 12L14.5 9.5L12 3Z" 
                      fill="white" 
                      filter="drop-shadow(0 0 2px rgba(255,255,255,0.3))"
                    />
                    {/* Secondary sparkles */}
                    <path d="M12 7L11 9.5L8.5 10.5L11 11.5L12 14L13 11.5L15.5 10.5L13 9.5L12 7Z" 
                      fill="#4040ff"
                    />
                    {/* Small decorative sparkles */}
                    <circle cx="7" cy="7" r="0.5" fill="white"/>
                    <circle cx="17" cy="7" r="0.5" fill="white"/>
                    <circle cx="7" cy="17" r="0.5" fill="white"/>
                    <circle cx="17" cy="17" r="0.5" fill="white"/>
                  </svg>
                </div>
                <div className="ai-title">
                  <h3>AI-Generated Summary</h3>
                  <p>Based on {placeData.total_reviews} Google reviews</p>
                </div>
              </div>
              <div className="ai-rating">★★★★★</div>
              <ul className="summary-points">
                {currentSummaryPoints.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>

            {/* Reviews Section */}
            <div className="reviews-section">
              {Array.isArray(reviews) && reviews.length > 0 ? (
                reviews.map((review, index) => (
                  <div key={index} className="review-item">
                    <div className="review-header">
                      <div className="reviewer-photo-container">
                        {review.profile_photo_url ? (
                          <img 
                            src={review.profile_photo_url} 
                            alt={review.author_name}
                            className="reviewer-photo"
                          />
                        ) : (
                          <div className="reviewer-initials">
                            {getInitials(review.author_name)}
                          </div>
                        )}
                      </div>
                      <div className="reviewer-info">
                        <div className="reviewer-name-container">
                          <a 
                            href={review.author_url} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="reviewer-name-link"
                          >
                            {review.author_name}
                          </a>
                          <span className="verified-badge" title="Verified Review">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                              {/* Shield background */}
                              <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4z" 
                                fill="#4285f4"
                              />
                              {/* Checkmark */}
                              <path d="M10 17l-4-4 1.4-1.4 2.6 2.6 6.6-6.6L18 9l-8 8z" 
                                fill="white"
                              />
                              {/* Subtle highlight */}
                              <path d="M12 1L3 5v.5L12 2l9 3.5V5l-9-4z" 
                                fill="white" 
                                fillOpacity="0.3"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="review-meta">
                          <span className="review-time">
                            {review.time ? new Date(review.time * 1000).toLocaleDateString() : 'Recent'}
                          </span>
                          <span className="review-source">on Google</span>
                        </div>
                        <div className="review-rating">
                          {[...Array(5)].map((_, i) => (
                            <span key={i} style={{color: i < review.rating ? '#ffd700' : '#666'}}>
                              ★
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className="review-text">{review.text || 'No comment provided.'}</p>
                  </div>
                ))
              ) : (
                <div className="no-reviews">
                  {isLoading ? 'Loading reviews...' : 'No reviews available yet.'}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GoogleRatingWidget; 