import React from 'react';
import '../styles/AnnouncementBar.css';

const AnnouncementBar = () => {
  return (
    <div className="announcement-bar">
      <p>Get Published: Transform Your Manuscript into a Book within 60 Days! Call us now at +1 213 457 3593!</p>
    </div>
  );
};

export default AnnouncementBar; 